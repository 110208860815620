/** @jsx jsx */
import { graphql } from "gatsby";
import { HeroCarousel } from "gatsby-theme-hero-carousel";
import React, { memo } from "react";
import Bleed from "shared/components/Bleed";
import useThemeUI from "shared/hooks/useThemeUI";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetHeroCarousel" }>;
}

const HeroCarouselWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  shouldBleed,
  widget,
  prevWidget,
  nextWidget,
}) => {
  const carouselConfig = widget.heroCarouselShape?.heroCarousel;
  const { theme } = useThemeUI();

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={"FULL"}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
      contained={false}
    >
      <Bleed
        top={
          shouldBleed && isFirst
            ? // FIXME: type this
              // @ts-expect-error no type for sectionVerticalGutter
              theme.sizes?.sectionVerticalGutter
            : undefined
        }
        bottom={
          shouldBleed && isLast
            ? // FIXME: type this
              // @ts-expect-error no type for sectionVerticalGutter
              theme.sizes?.sectionVerticalGutter
            : undefined
        }
        horizontal={
          shouldBleed
            ? // FIXME: type this
              // @ts-expect-error no type for containerGutter
              theme.sizes?.containerGutter
            : undefined
        }
      >
        <HeroCarousel isNested={isNested} slides={carouselConfig?.items} />
      </Bleed>
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetHeroCarousel on WidgetHeroCarousel {
    id
    heroCarouselShape {
      heroCarousel {
        id
        name
        items {
          movie {
            id
            path
            certificate
            runtime
            poster
            trailer {
              SD
              HD
            }
          }
          event {
            id
            title
            path
          }
          caption
          subCaption
          image {
            src
            alt
          }
          button {
            label
            link
          }
        }
      }
    }
    __typename
  }
`;

export default memo(HeroCarouselWidget);
